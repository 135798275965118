@import '../../styles/index.scss';

.bc-footer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    @include respond-to($computer) {
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
    }
    &-support,
    &-poweredBy {
        font-size: 18px;
        padding: 0 15px;
        margin: 0;
        color: $grey;
    }
    &-poweredBy {
        align-self: center;
    }
}
