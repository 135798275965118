@font-face {
    font-family: 'Rubik-Medium';
    src: local('Rubik-Medium'),
        url(./assets/fonts/Rubik-Medium.ttf) format('truetype');
}
@font-face {
    font-family: 'Lato-Bold';
    src: local('Lato-Bold'),
        url(./assets/fonts/Lato-Bold.ttf) format('truetype');
}
@font-face {
    font-family: 'Lato-Regular';
    src: local('Lato-Regular'),
        url(./assets/fonts/Lato-Regular.ttf) format('truetype');
}
