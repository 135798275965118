/*==========  Mobile First Method  ==========*/
/* Extra Small Devices, Phones */
/* Medium Devices, Desktops */
/*==========  Non-Mobile First Method  ==========*/
/* Extra Small Devices, Phones */
/* Small Devices, Tablets */
/* Medium Devices, Desktops */
body {
  background-color: #e5e5e5;
}

.bc-link {
  color: #0091ff;
  text-decoration: underline;
}

.bc-wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 15px auto;
}
@media only screen and (min-width: 481px) {
  .bc-wrapper {
    position: relative;
    max-width: 900px;
  }
}
