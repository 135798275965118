/*==========  Mobile First Method  ==========*/
$computer: '(min-width: 481px)'; /* Extra Small Devices, Phones */
$desktop: '(min-width: 992px)'; /* Medium Devices, Desktops */

/*==========  Non-Mobile First Method  ==========*/
$phone: '(max-width: 480px)'; /* Extra Small Devices, Phones */
$medium: '(max-width: 767px)'; /* Small Devices, Tablets */
$portable: '(max-width: 991px)'; /* Medium Devices, Desktops */

@mixin respond-to($media) {
    @media only screen and #{$media} {
        @content;
    }
}
